import { forIn } from 'lodash';
import { ConfigId, RUNTIME_CONFIG_MAP } from 'utils/config';

import { CompanyName } from './types';

/**
 * @param domain URL domain name
 * @returns the configuration ID associated with the domain. Defaults to the ID for Endpoint configuration.
 */
export const getConfigIdFromDomain = (domain: string): ConfigId => {
  let configurationId = ConfigId.Endpoint;

  forIn(ConfigId, (configId) => {
    if (Number.isNaN(Number(configId))) return;

    if (domain.includes(RUNTIME_CONFIG_MAP[configId].domainId)) {
      configurationId = configId;
    }
  });

  return configurationId;
};

/**
 * @param id configuration ID
 * @returns the company name associated with the configuration ID
 */
export const getCompanyNameFromConfigId = (id: ConfigId): CompanyName => RUNTIME_CONFIG_MAP[id].companyName;

const removeExtraConfigScripts = (configId: ConfigId) => {
  document.querySelectorAll('script').forEach((script) => {
    if (script.parentNode && script.src.includes('config.js') && !script.src.includes(`org/${configId}`)) {
      script.parentNode.removeChild(script);
    }
  });
};

const removeExtraGlobalConfig = (configId: ConfigId) => {
  if (configId === ConfigId.Endpoint) {
    delete window.CONFIG[ConfigId.AHC];
    delete window.CONFIG[ConfigId.OpsTest];
    delete window.CONFIG[ConfigId.QETestOrg];
    delete window.CONFIG[ConfigId.QETestOrg2];
  } else if (configId === ConfigId.AHC) {
    delete window.CONFIG[ConfigId.Endpoint];
    delete window.CONFIG[ConfigId.OpsTest];
    delete window.CONFIG[ConfigId.QETestOrg];
    delete window.CONFIG[ConfigId.QETestOrg2];
  } else if (configId === ConfigId.QETestOrg) {
    delete window.CONFIG[ConfigId.AHC];
    delete window.CONFIG[ConfigId.Endpoint];
    delete window.CONFIG[ConfigId.OpsTest];
    delete window.CONFIG[ConfigId.QETestOrg2];
  } else if (configId === ConfigId.QETestOrg2) {
    delete window.CONFIG[ConfigId.AHC];
    delete window.CONFIG[ConfigId.Endpoint];
    delete window.CONFIG[ConfigId.OpsTest];
    delete window.CONFIG[ConfigId.QETestOrg];
  } else if (configId === ConfigId.OpsTest) {
    delete window.CONFIG[ConfigId.AHC];
    delete window.CONFIG[ConfigId.Endpoint];
    delete window.CONFIG[ConfigId.QETestOrg];
    delete window.CONFIG[ConfigId.QETestOrg2];
  }
};

/**
 * Removes app configuration for all other partners.
 * This is a temporary workaround until we can revisit our configuration strategy (EP-3500).
 * @param configId the configuration ID associated with the app
 */
export const removeExtraConfig = (configId: ConfigId) => {
  removeExtraConfigScripts(configId);
  removeExtraGlobalConfig(configId);
};

export function toCompanyName(value: string): CompanyName {
  if (['Endpoint', 'AHC', 'QETestOrg', 'QETestOrg2', 'Ops'].includes(value)) {
    return value as CompanyName;
  }

  return 'Endpoint' as CompanyName;
}
